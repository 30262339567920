import {HttpClient, HttpHeaders, HttpParams, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HandleError, HttpErrorHandler} from '../../shared/http-error-handler.service';
import {environment} from '../../../environments/environment';
import {FilingData} from '../model/filing-data';
import {FormSection} from '../model/form-section';
import {FilingBlank} from '../model/filingblank';
import {InputErrorWarning} from '../model/input-error-warning';
import {FormQuestion} from '../model/form-question';
import {IAssignmentKey} from '../model/assignment-key';
import {OnlineSubmissions} from '../model/online-submissions';
import {OnlineSubmissionsStatus} from '../model/online-submissions-status';
import { User } from '../../model/user';
import { FileUploadAttachments } from '../model/attachmentsFileUpload';
import { DownloadFileAttachment } from '../model/downloadFileAttachment';

@Injectable({
  providedIn: 'root'
})
export class OnlineDataEntryService {

  readonly handleError: HandleError;
  schema: string;
  activeSection: string = '';
  lastTarget: string = '';
  dataChanged: boolean = false;
  isFormFormatError: boolean = false;
  dataUpdates: any = {};
  dataChange = new BehaviorSubject<FormSection[]>([]);
  activeNode = new BehaviorSubject<any>({});
  loading = new BehaviorSubject<boolean>(false);
  clickedName: string;
  filingBlank: FilingBlank;
  originalFilingBlank: FilingBlank;
  changedStatus: boolean = false;
  showErrorMessages = new BehaviorSubject<boolean>(false);
  selectedFormSection: FormSection;
  fieldName: any;
  isFormUpdated: boolean = false;
  targetFilingBlank: FilingBlank;
  sectionErrors: InputErrorWarning[];
  sectionWarnings: InputErrorWarning[];
  sectionForm: FormSection;
  allSections: FilingBlank;
  formSummary: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  uploadAttachments: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  allSectionMessages: any[] = [];
  newRuleKeys: string[] = [];
  masterSectionForm: any[] = [];
  masterSelectedValues: any;
  isMasterValueEmpty: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  fileURIs: string[] = [];
  btnClicked: string;
  isSummaryPage: boolean = false;
  fileUpload: FileUploadAttachments;
  isFileUploaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  allSectionsForSummary: any;
  assignmentKey: any;
  postSaveAttachments: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  masterAttachments: any;
  isAllowAttachments: boolean;
  isValidated: boolean;

  get data(): FormSection[] {
    return this.dataChange.value;
  }

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('OnlineDataEntryService');
    this.initialize();
  }

  setFieldName(fieldName: any): any {
    this.fieldName = fieldName;
  }

  getFieldName(): any {
    return this.fieldName;
  }

  setSelectedSection(formSection: FormSection) {
    this.selectedFormSection = formSection;
  }

  getSelectedFormSection(): FormSection {
    return this.selectedFormSection;
  }

  setClickedName(name: string) {
    this.clickedName = name;
  }

  getClickedName(): string {
    return this.clickedName;
  }

  setFilingBlank(filingBlank: FilingBlank) {
    this.filingBlank = filingBlank;
    this.dataChange.next(this.filingBlank.sections);
  }

  getFilingBlank(): FilingBlank {
    return this.filingBlank;
  }

  setTargetFilingBlank(targetFilingBlank: FilingBlank) {
    this.targetFilingBlank = targetFilingBlank;
  }

  getTargetFilingBlank() {
    return this.targetFilingBlank;
  }

  setChangeStatus(changedStatus: boolean) {
    this.changedStatus = changedStatus;
  }

  getChangeStatus(): boolean {
    return this.changedStatus;
  }

  setOriginalFilingBlank(filingBlank: FilingBlank) {
    this.originalFilingBlank = filingBlank;
  }

  getOriginalFilingBlank(): FilingBlank {
    return this.originalFilingBlank;
  }

  totalErrors(filing: FilingBlank): number {
    let totalErrors = 0;
    if (filing) {
      filing.sections.forEach((section: FormSection) => {
        this.countErrors(section);
      });
    }
    totalErrors = this.sectionErrors.length;
    return totalErrors;
  }

  totalWarnings(filing: FilingBlank): number {
    let totalWarnings = 0;
    if (filing) {
      filing.sections.forEach((section: FormSection) => {
        this.countWarnings(section);
      });
    }
    totalWarnings = this.sectionWarnings.length;
    return totalWarnings;
  }


  countErrors(section: FormSection): number {
    this.sectionForm = section;
    let errorCount = 0;
    section.questions.forEach((question: FormQuestion) => {
      let errors: string[] = [];
      question.inputs.forEach(input => {
        if (input.errors) {
          input.errors.forEach(error => {
            if (errors.indexOf(error.message) === -1) {
              errors.push(error.message);
            }
            if (this.sectionErrors.indexOf(error.message) === -1) {
              this.sectionErrors.push(error.message);
            }
          });
          errorCount = this.sectionErrors.length;
        }
      });
    });
    return errorCount;
  }

  countWarnings(section: FormSection): number {
    let warningCount = 0;
    section.questions.forEach((question: FormQuestion) => {
      let warnings: string[] = [];
      question.inputs.forEach(input => {
        if (input.warnings) {
          input.warnings.forEach(warning => {
            if (warnings.indexOf(warning.message) === -1) {
              warnings.push(warning.message);
            }
            if (this.sectionWarnings.indexOf(warning.message) === -1) {
              this.sectionWarnings.push(warning.message);
            }
          });
          warningCount = this.sectionWarnings.length;
        }
      });
    });
    return warningCount;
  }


  // get all meaasges of sections
  getAllMessagesOfSections(filingBlank: FilingBlank): any [] {
    this.allSectionMessages = [];
    for (let section of filingBlank.sections) {
      this.mapAllMessagesOfSections(section);
    }
    return this.allSectionMessages;
  }

  // map all the messages to messages array of section and it's childersn
  mapAllMessagesOfSections(sections: FormSection) {
    for (const question of sections.questions) {
      for (const input of question.inputs) {
        input.errors.forEach(ele => {
          this.allSectionMessages.push({
            error: true,
            message: ele.message
          });
        });
        input.warnings.forEach(ele => {
          this.allSectionMessages.push({
              warning: true,
              message: ele.message
            }
          );
        });
      }
    }
    for (let section of sections.sections) {
      (this.mapAllMessagesOfSections(section));
    }
    return this.allSectionMessages;
  }


  getFilingBlankLayout(schema: string): Observable<any> {
    type bodyType = 'body';
    const url = `${environment.apiUrl}${schema}/filingblanklayout `;
    const headers = new HttpHeaders({'Accept': 'application/json'});
    const options = {headers: headers, observe: <bodyType>'response'};
    return this.httpClient.get(url, options);
  }

  initialize() {
    let data = [];

    this.dataChange.next(data);
  }

  setAllSections(allSections:FilingBlank){
  this.allSections=allSections;
  }

  getAllSections():FilingBlank{
   return this.allSections;
  }

  // Api to get all the data entries
  getAllDataEntries(schema: string, assignmentKey: IAssignmentKey): Observable<any> {
    let url = `${environment.apiUrl}${schema}/filingblank`;
    let headers = new HttpHeaders({'Content-Type': 'application/json'});
    let params = new HttpParams();
    // params = params.append('assignmentKey', JSON.stringify(params));
    params = params.append('assignmentField', assignmentKey.assignmentField);
    params = params.append('assignmentValue', assignmentKey.assignmentValue);

    let options;
    options = {headers: headers, params};
    return this.httpClient.get(url, options)
      .pipe(catchError(this.handleError('putFilingData', null)));
  }

  getFilingBlanks(schema: string, returnTarget: string, assignmentKey: IAssignmentKey): Observable<any> {
    let url = `${environment.apiUrl}${schema}/filingblank`;
    let headers = new HttpHeaders({'Content-Type': 'application/json'});
    let params;
    params = new HttpParams().set('target', returnTarget);
    params = params.append('assignmentField', assignmentKey.assignmentField);
    params = params.append('assignmentValue', assignmentKey.assignmentValue);
    let options;
    options = {headers: headers, params: params};
    return this.httpClient.get(url, options)
      .pipe(catchError(this.handleError('putFilingData', null)));

  }


  postFilingData(schema: string, filingData: any, assignmentKey: IAssignmentKey): Observable<FilingBlank> {
    let fd: any = {...filingData, assignmentKey, dependentKeys: this.newRuleKeys, attachments: this.masterAttachments};
    let url = `${environment.apiUrl}${schema}/filingblank/filingdata/submission`;
    let headers = new HttpHeaders({'Content-Type': 'application/json'});
    const params = new HttpParams();
    let options = {headers: headers, params: params};
    return this.httpClient.post<FilingBlank>(url, fd, options);
    // .pipe(catchError(this.handleError('postFilingData', null)));
  }


  validateFilingData(schema: string, filingData: any, assignmentKey: IAssignmentKey, returnTarget: string): Observable<FilingBlank> {
    let fd: any = {data: filingData, assignmentKey, dependentKeys: this.newRuleKeys, attachments: this.masterAttachments};
    let url = `${environment.apiUrl}${schema}/filingblank/filingdata/validation`;
    let headers = new HttpHeaders({'Content-Type': 'application/json'});
    let params = new HttpParams().set('returnTarget', returnTarget);
    let options = {headers: headers, params: params};
    return this.httpClient.post<FilingBlank>(url, fd, options)
      .pipe(catchError(this.handleError('validateFilingData', null)));
  }

  saveFilingData(schema: string, filingData, assignmentKey: IAssignmentKey, returnTarget: string): Observable<FilingBlank> {
    let fd: any = {data: filingData, assignmentKey, dependentKeys: this.newRuleKeys, attachments: this.masterAttachments};
    let url = `${environment.apiUrl}${schema}/filingblank/filingdata`;
    let headers = new HttpHeaders({'Content-Type': 'application/json'});
    let params = new HttpParams().set('returnTarget', returnTarget);
    let options = {headers: headers, params: params};
    return this.httpClient.put<FilingBlank>(url, fd, options)
      .pipe(catchError(this.handleError('saveFilingData', null)));
  }

  onlineSubmissions(schema: string): Observable<any> {
    const url = `${environment.apiUrl}${schema}/onlineFilings/currentuser`;
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const options = {headers: headers};
    // return the get method observable so that I can handle errors in a specific way.
    // see getAllOnlineSubmissions() method in user-filing-component
    return this.httpClient.get(url, options);
  }

  /**
   * Return the observable from the request to /onlineFilings with assignment key query params.
   * Return the observable without piping it through the catch error function so that I can handle errors gracefully.
   * @see filing-search component
   * @param schema
   * @param assignmentKey
   * @param assignmentKeyValue
   */
  getOnlineFilingsByAssignmentKey(schema: string, assignmentKey: string, assignmentKeyValue: string): Observable<any> {

    const url = `${environment.apiUrl}${schema}/onlineFilings?assignmentField=${assignmentKey}&assignmentValue=${assignmentKeyValue}`;
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const options = {headers: headers};

    return this.httpClient.get<OnlineSubmissions>(url, options);
  }

  getOnlineFilingsByUserId(schema: string, userId: string): Observable<any> {

    const url = `${environment.apiUrl}${schema}/onlineFilings?userId=${userId}`;
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const options = {headers: headers};

    return this.httpClient.get<OnlineSubmissions>(url, options);
  }

  getPreSignedUrlForAttachmentsfromS3(user: User, fileName: string): Observable<any> {
    const url = `${environment.apiUrl}${user.currentSchema}/attachments/url/${fileName}`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = {
      headers: headers
    };
    return this.httpClient.get<any>(url, options)
      .pipe(catchError(this.handleError('getPreSignedUrlForAttachmentsfromS3', null)));
  }

  uploadAttachmentstoS3(formData: File, uploadURL: string): Observable<any> {
    const req = new HttpRequest('PUT', uploadURL, formData, {
      reportProgress: true
    });
    return this.httpClient.request(req)
      .pipe(catchError(this.handleError('uploadAttachmentstoS3', null)));
  }

  postAttachmentsFilingData(user: User, s3FileName: any, originalFileName:any, assignmentKey: any): Observable<FilingBlank> {
    let attachmentRequest: any = {fileName: s3FileName, originalFileName: originalFileName, assignmentField: assignmentKey.assignmentField, assignmentValue: assignmentKey.assignmentValue};
    let url = `${environment.apiUrl}${user.currentSchema}/attachments/save`;
    let headers = new HttpHeaders({'Content-Type': 'application/json'});
    let options = {headers: headers};
    return this.httpClient.post<FilingBlank>(url, attachmentRequest, options)
    .pipe(catchError(this.handleError('postAttachmentsFilingData', null)));
  }

  deleteAttachment(schema: string, event, assignmentKey: any): Observable<any> {
    let url = `${environment.apiUrl}${schema}/attachments/delete`;
    let httpParams = new HttpParams()
      .set('fileName', event.fileName)
      .set('originalFileName', event.originalFileName)
      .set('assignmentField', assignmentKey.assignmentField)
      .set('assignmentValue', assignmentKey.assignmentValue);
    
    let headers = new HttpHeaders({'Content-Type': 'application/json'});
    let options = {headers: headers, params: httpParams};
    
    const req = new HttpRequest('DELETE', url, options);
    return this.httpClient.request(req);
  }

  getDownloadUrlfromS3(user: User, fileName: string): Observable<DownloadFileAttachment> {
    const url = `${environment.apiUrl}${user.currentSchema}/attachments/download/url/${fileName}`;
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const options = {
      headers: headers
    };
    return this.httpClient.get<DownloadFileAttachment>(url, options)
      .pipe(catchError(this.handleError('getDownloadUrlfromS3', null)));
  }

  /**
   * This method updates an online submissions object's status from a string to the corresponding value of the online submission status enum.
   * @param onlineSubmissionsObject
   */
  transformStatus(onlineSubmissionsObject: OnlineSubmissions) {
    switch (onlineSubmissionsObject.status) {
      case 'NOT_STARTED' :
        onlineSubmissionsObject.status = OnlineSubmissionsStatus.NOT_STARTED;
        return onlineSubmissionsObject;
        break;
      case 'IN_PROGRESS' :
        onlineSubmissionsObject.status = OnlineSubmissionsStatus.IN_PROGRESS;
        return onlineSubmissionsObject;
        break;
      case 'PROCESSING' :
        onlineSubmissionsObject.status = OnlineSubmissionsStatus.PROCESSING;
        return onlineSubmissionsObject;
        break;
      case 'SUBMITTED' :
        onlineSubmissionsObject.status = OnlineSubmissionsStatus.SUBMITTED;
        return onlineSubmissionsObject;
        break;
      case 'UNKNOWN' :
      default:
        onlineSubmissionsObject.status = OnlineSubmissionsStatus.UNKNOWN;
        return onlineSubmissionsObject;
    }
  }

  setFormData(formSection: FormSection): any[] {
    let questions: any[] = [];
    let obj;
    formSection.headings.forEach((x) => {
      obj = {};
      obj['key'] = '';
      obj['text'] = '';
      obj['order'] = x.order;
      obj['heading'] = x.heading;
      obj['inputs'] = [];
      questions.push(obj);
    });
    formSection.questions.forEach((x) => {
      obj = {};
      obj["key"] = x.key;
      obj["label"] = x.label;
      obj['text'] = x.text;
      obj['order'] = x.order;
      obj['heading'] = '';
      obj['inputs'] = x.inputs;
      questions.push(obj);
    });

    const referenceFields = Array.from(
      new Set(questions.flatMap((item) => Object.keys(item)))
    );

    // Add missing fields and create new objects
    const updatedData = questions.map((item) => {
      const missingFields = referenceFields.filter(
        (field) => !item.hasOwnProperty(field)
      );
      const updatedItem = { ...item };

      missingFields.forEach((field) => {
        updatedItem[field] = ''; // Add missing fields with null values
      });

      return updatedItem;
    });

    return updatedData.slice().sort((a, b) => a.order - b.order);
  }

  setFileUpload(fileUpload: FileUploadAttachments) {
    this.fileUpload = fileUpload;
  }

  setSummaryPageSections(allSections: any) {
    this.allSectionsForSummary = allSections;
  }

  getSummaryPageSections(): any {
     return this.allSectionsForSummary;
  }
}


