<div class="wrapper">

    <div [formGroup]="sectionForm">
      <div [formGroupName]="section.key" class="well well-lg" [id]="section.key">
  
        <div class="row pb-1" *ngIf="isSectionVisible">
          <div class="col-6">
            <h4>
              <span>
                {{section.name}}
              </span>
            </h4>
          </div>
        </div>
  
        <div *ngIf="isSectionVisible">
          <div *ngFor="let model of updatedModel;">
            <div class="row pl-3" *ngIf="model.label === '' else question">
              <!-- Heading -->
              <ng-container >
                <div class="col">
                  <h5>{{model.heading}}</h5>
                </div>
              </ng-container>
              <hr/>
            </div>
            <ng-template #question>
                <!-- Questions -->
                <div [formGroupName]="model.label" class="col well well-lg indent-questions" [id]="model.label">
                  <table>
                    <tbody>
                      <tr>
                        <td style="width: 1rem;"><b>{{getQuestionNumber(model.label)}}</b></td>
                        <td style="width: 30rem;">
                          <a (click)="goToSelectedQuestion(model.text, model.key, section.key)">
                            <u>{{model.text}}</u>
                          </a>
                        </td>
                        <td style="width: 2rem;">{{model.primaryKeyCount}}/{{model.associatedRuleCount}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- More Sections -->
  <ng-container>
    <app-set-dependencies-select-questions *ngFor="let child of section.sections; let i = index"
                      [section]="child" 
                      [parentId]="getSectionId()"
                      [filteredQuestions] = "filteredQuestions"
                      [isSectionVisible] = "isSectionVisible">
      </app-set-dependencies-select-questions>
  </ng-container>
  